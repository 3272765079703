/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  height: 14px;
  --c: #aaff00 92%, #0000;
  background:
    radial-gradient(circle closest-side, var(--c)) calc(100%/-4) 0,
    radial-gradient(circle closest-side, var(--c)) calc(100%/4) 0;
  background-size: calc(100%/2) 100%;
  animation: l14 1.5s infinite;
  position: absolute;
  right: -5px;
  top: 10px;
  scale: 0.7;
}

@keyframes l14 {
  0% {
    background-position: calc(100%/-4) 0, calc(100%/4) 0
  }

  50% {
    background-position: calc(100%/-4) -14px, calc(100%/4) 14px
  }

  100% {
    background-position: calc(100%/4) -14px, calc(3*100%/4) 14px
  }
}